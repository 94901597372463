export const REQUEST_URLS = {
  GET_COUNTRY: "/v2/account/public/country/of/residence",
  SUBMIT_FORM: "/v2/account/public/submit/gtx/account",
  COIN_IMG_LIST: "/v2/markets/public/get/instrument/icons?code=",
  PAGEHOME: "/v2/portal/public/pagebase/pagehome",
  GOOGLE_RECAPTCHA_VERIFY: "/v2/account/auth/recaptcha/verify",
  GEETEST_INIT: "/v2/account/auth/init",
  GEETEST_VALIDATE: "/v2/account/auth/validate",
  WALLET_AUTH: "/v2/account/auth/metamask",
  GET_CODE: "/v2/account/auth/trading/qrlogin/get",
  QR_LOGIN_CHECK: "/v2/account/auth/trading/qrlogin/check",
  LOGIN: "/v2/account/auth/trading/login",
  USER_REGISTER: "/v2/account/auth/trading/register",
  CONFIRM_MAIL: "/v2/account/auth/trading/confirm/mail",
  GET_RECAPTCHA_KEY: "/v2/account/auth/recaptcha/get-site-key?x-device-type=web",
  LOGIN_TFA: "/v2/account/tfa/login/v2",
  SEND_MAIL: "/v2/account/auth/trading/send/mail",
  RESEND_MAIL: "/v2/account/auth/trading/resend/mail",
  REGISTER_KYC_SUBMIT: "/v2/account/protected/register/submit",
  GET_RESIDENCE: "/v2/account/protected/country/of/residence",
  GET_CITIZENSHIP: "/v2/account/protected/country/of/citizenship",
  CHECK_NEW_IP: "/v2/account/public/detect/account",
  REST_PASSWORD: "/v2/account/auth/trading/reset/password",
  GOOGLE_LOGIN: "/v2/account/auth/google/login",
  GOOGLE_REGISTER: "/v2/account/auth/google/register",
  IS_USER_IP_US: "/v2/account/public/detect/account",
  GET_IP_BLOCK: "/v2/account/protected/ip/block/warning",
  GET_TICKERS: "/v2/ticker",
  AMM_POOL: "/v2/amm/public/amm/statistic/pool",
  GET_TICKSIZE_LIST: "/v2/markets/public/instruments/coins/base/tick",
  GET_ALL_BALANCES: "/v2/account/protected/balance/info/",
  GET_AMM_COINS: "/v2/markets/public/amm/collateral/coin/list",
  GET_AMM_MARKETS: "/v2/amm/public/amm/markets",
  GET_ICONS: "/v2/markets/public/get/instrument/icons?code=",
  GET_DECIMALS: "/v2/markets/public/instruments/coins/accuracys/all",
  GET_TRADE_LIST: ["/v2/dbworker/public/", "/exchange/candles?granularity=86400"],
  SUBMIT_AMM: "/v2/amm/protected/amm/apply",
  BING_CF_ACCOUNT: "/v2/account/protected/bind/cf/account",
  GET_CLAIM_HISTORY_PENDING: "/v2/account/protected/pending/claim",
  GET_CLAIM_HISTORY: "/v2/account/protected/all/claims",
  GET_LAST_CLAIM: "/v2/account/protected/latest/claim",
  GET_APPLICATION: "/v2/account/protected/claim/application",
  ADD_CLAIM: "/v2/account/protected/claim/add",
  GET_BALANCE: "/v2/account/protected/deposit/balance/v2?instrument=",
  GET_LAST_APPLICATION: "/v2/account/protected/latest/application",
  GET_CLAIMS_EXCHANGE: "/v2/markets/protected/claims",
  GET_CLAIM_LINK: "/v2/account/protected/agreement/link",
  GET_CLAIM_BALANCE: "/v2/account/protected/claim/balances",
  GET_REOX_HISTORY: "/v2/account/protected/convert/reox/history",
  ACCOUNT_INFO: "/v2/account/protected/accountinfo",
  ALL_MARKET: "/v2/markets/public/markets/all",
  GET_COINS: "/v2/markets/public/amm/collateral/coin/list",
  GET_EXECUTING_LP: "/v2/amm/protected/amm/history",
  GET_ADD_WITHDRAW: "/v2/amm/protected/amm/transfer/balance/",
  SUBMIT_TRAMFER: "/v2/amm/protected/amm/transfer",
  GET_BALANCE_BREAKDOWN: "/v2/amm/protected/amm/balance/breakdown/",
  GET_REDEEM_MODAL: "/v2/amm/protected/amm/redeem/detail/",
  RISK_TIER: "/v2/ohlc/public/risktiers/",
  GET_ZONE_REDEEM: "/v2/account/protected/permissionless/amm/redeem",
  GET_REDEEM: "/v2/amm/protected/amm/redeem",
  GET_REOX_CONVERT: "/v2/account/protected/estimate/reox",
  SUBMIT_CONVERT_OX: "/v2/account/protected/convert/to/reox",
  GET_MARKETS: "/v2/markets/public/markets/detail",
  GET_OUSD_BACKING: "/v2/portal/public/ousd/backing/value?",
  GET_TICKERS_OX: "/v2/cache/public/ticker/OX-USDT",
  GET_BORROWED_TOTAL: "/v2/portal/public/ousd/borrowed/total",
  GET_OUSD_TOTAL: "/v2/accvalue/public/ousd/total",
  GET_BACKING_TOTAL: "/v2/portal/public/ousd/backing/total",
  GET_OUTSTANDING_CURVE: "/v2/portal/public/ousd/outstanding/curve?timeEnum=",
  GET_MARKET_PRICE: "/v2/cache/public/markprice/",
  GET_REDEEM_MAX_AMOUNT: "/v2/vault/protected/redemption/amount",
  SUBMINT_OUSD_REDEEM: "/v2/vault/protected/coin/convert",
  SUBMIT_OUSD_MINT: "/v2/vault/protected/ousd/convert",
  GET_VAULT_APR: "/v2/vault/public/vaultSnapshots/get_apr",
  GET_VAULT_STAKED: "/v2/vault/protected/vaultSummary/getRecordByAccountIdAndPoolId",
  SUBMIT_VAULT_STAKE: "/v2/vault/protected/vaultRecord/stake",
  SUBMIT_VAULT_UNSTAKE: "/v2/vault/protected/vaultRecord/unstake",
  GET_CONVERT_RADIO: "/v2/account/protected/convert/ratio",
  GET_REWARDS_FMT: "/v2/account/protected/TERRA/rewards/page",
  LINK_TERRA_ADDRESS: "/v2/account/protected/TERRA/link-address",
  GET_TERRA_LINKED_ADDRESS_LIST: "/v2/account/protected/TERRA/link-address/list",
  DELETE_TERRA_LINKED_ADDRESS: "/v2/account/protected/TERRA/link-address/",
  DELETE_TERRA_LINKED_ADDRESS_LIST: "/v2/account/protected/TERRA/bulk-link-address",
  GET_FMT_REWARDS_HISTORY: "/v2/account/protected/TERRA/airdrop/history",
  GET_AMM_MARKET: "/v2/amm/public/amm/getAnchorCoinMarket?coin=",
  GET_OUSD_AVG_PRICE: "/v2/vault/public/depth/ousd/avg/price?marketCode=oUSD-USDT",
  GET_REFERRAL_CHAT: "/v2/account/protected/referral/dashboard",
  GET_REFERRAL_TABLE: "/v2/account/protected/referral/history",
  SHART_CODE: "/v2/account/protected/share/user/info",
  UPADLO_PDF_LIST: "/v2/account/protected/upd/equity/files",
  GET_KYC_FULLNAME: "/v2/account/protected/kyc3/info/fullname",
  GET_AIRDROP_RATE: "/v2/accvalue/public/oxRebaseConfig/getRecord",
  GET_TASKS: "/v2/accvalue/protected/oxRebaseRewards/getTasks",
  GET_TASK_LIST: "/v2/accvalue/protected/oxRebaseRewards/getRecordList",
  VALIDATE_ADDRESS: "/v2/account/auth/validate/bind/",
  GET_ADDRESS_NONCE: "/v2/account/auth/metamask/users",
  GET_OXLEADER_TOP: "/v2/accvalue/public/position/top-ox-leaderboard",
  GET_AOXLEADER_TOP: "/v2/accvalue/public/position/top-aox-leaderboard",
  GET_MY_RANK: "/v2/accvalue/protected/position/my-ox-rank",
  GET_OX_TABLE: "/v2/accvalue/public/position/ox-leaderboard/page",
  GET_AOX_TABLE: "/v2/accvalue/public/position/aox-leaderboard/page",
  GET_PUBLIC_CONVERTIBLE_COIN: "/v2/account/public/convertible/coins",
  GET_CONVERTIBLE_COIN: "/v2/account/protected/convertible/coins",
  GET_PUBLIC_CONVERTIBLE_RATE: "/v2/account/public/estimated/rate",
  GET_CONVERTIBLE_RATE: "/v2/account/protected/estimated/info",
  GET_COIN_BALANCE: "/v2/account/protected/withdraw/balance/",
  SUBIMIT_CONVERTIBLE_COIN: "/v2/account/protected/ox/conversion",
  GET_CONVERTIBLE_HISTORY: "/v2/account/protected/conversion/history",
  SUBMIT_MASTER_TRADER: "/v2/vault/protected/master/apply",
  GET_CURRENT_COPY_TRADING_INFO: "/v2/vault/protected/copy-trading/info",
  GET_MASTER_TRADERS: "/v2/vault/public/master/accounts",
  GET_MASTER_TRADES_PROFILE: "/v2/vault/public/single/master?masterAccountId=",
  STOP_MASTER_TRADER: "/v2/vault/protected/master/stop",
  START_MASTER_TRADER: "/v2/vault/protected/master/start",
  SUBSCRIBE_MASTER_TRADER: "/v2/vault/protected/vault/subscribe",
  UNSUBSCRIBE_MASTER_TRADER: "/v2/vault/protected/vault/unsubscribe",
  GET_ALL_MASTER_TRADER_PEOPLE: "/v2/vault/protected/following/ids",
  GET_FOLLOWING_MASTER_TRADERS: "/v2/vault/protected/following/master",
  GET_COPY_TRADING_PNL: "/v2/vault/protected/subscribe/pnl",
  GET_UNSUBSCRIBE_INFO: "/v2/vault/protected/unsubscribe/info?masterAccountId=",
  UPDATE_MASTER_TRADER_INFO: "/v2/vault/protected/master/profile/edit",
  GET_PNL_HISTORY: "/v2/vault/public/vault/pnl/history",
  GET_MASTER_OPEN_POSITION_HISTORY: "/v2/account/public/vault/open-position",
  GET_MASTER_OPEN_ORDERS_HISTORY: "/v2/markets/public/vault/open-orders",
  GET_MASTER_TRADE_HISTORY: "/v2/interohlc/public/vault/trade-history",
  GET_MASTER_TRANSACTION_HISTORY: "/v2/account/public/vault/transaction-history",
  GET_UNSUBSCRUBE_LIST: "/v2/vault/protected/vault/getUnsubscribeList",
  GET_COPY_TRADE_PICTURES: "/v2/vault/public/master/profile/list",
  BIND_TWITTER_CONNECT: "/v2/account/protected/twitter2/render?callback=",
  GET_OX_PRICE: "/v2/markets/public/markets/OX-USDT",
  GET_VAULT_POOL: "/v2/lending/public/feel/vault/pool",
  GET_POOL_BALANCE: "/v2/lending/public/feel/vault/balance",
  SUBMIT_POOL_STAKE: "/v2/lending/protected/feel/vault/stake",
  GET_POOL_VAULT_HISTORY: "/v2/lending/protected/feel/vault/reward/history",
  CALCULATION_URL: "/v2/account/protected/collateral/balance/search",
  COLLATERAL_LIST: "/v2/markets/public/risk/linear/collateral/list",
  GET_PRISON_LOCK: "/v2/lending/protected/prison/lock",
  GET_MISSION_TASK: "/v2/accvalue/protected/xOxRebaseRewards/getTasks",
  GET_MISSION_HISTORY: "/v2/accvalue/protected/xOxRebaseRewards/getRecordList",
  GET_PRISON_LOCKED_TOTAL: "/v2/lending/public/prison/locked",
  GET_USER_XOX_AMOUNT: "/v2/lending/protected/prison/balance",
  PRISON_LOCK_AMOUNT: "/v2/lending/protected/prison/locked",
  GET_PRISON_LOCK_BALANCE: "/v2/lending/protected/prison/bail/balance",
  SUBMIT_PRISON_LOCK: "/v2/lending/protected/prison/all/bail",
  CESTOMIZED_LINK: "/v2/account/protected/customized/affiliate/link",
  TESTNET_FAUCET: "/v2/account/public/oxFaucet/insert",
  GET_POINTS_HISTORY: "/v2/accvalue/public/ox/chain/points?address=",
  GET_FEE_CHART: "/v2/accvalue/public/analytics/statistics/fees",
  GET_REFERRA_CHART: "/v2/accvalue/public/referral/fees",
  GET_INTEREST_CHART: "/v2/accvalue/public/open/interest",
  GET_RATIO_CHART: "/v2/accvalue/public/position/ratio",
  GET_MARKETCODE_PRICE: "/v2/market/public/markets/",
  GET_VOLUME_ALL: "/v2/dbworker/public/all/trading/volume",
  GET_COPY_TRADING_PNL_CHART: "/v2/vault/public/vault/pnl/history/chart",
  VAULTS_CLAIM: "/v2/vault/protected/copy-trading/claim/",
  VALUTS_PENDING_REWARDS: "/v2/vault/protected/copy-trading/claim/reward/",
  STAKED_OX: "/v2/vault/protected/copy-trading/subscribe/staked?vaultAccountId=",
  REFERRAL_RATE: "/v2/account/public/account/referral-rate/get",
  GET_CANDLES: ["/v2/dbworker/public/", "/exchange/candles"],
  GET_AMM_VAULT_COINS: "/v2/vault/public/amm/vault/retrieveAllowedMortgage",
  SUBMIT_AMM_VALUT: "/v2/vault/protected/vault/lending/subscribe",
  GET_CONVERT_OX: "/v2/account/public/estimated/fixed/conversion/rate",
  GET_PUBLIC_CONVERT_OX: "/v2/account/protected/estimated/fixed/conversion/info",
  GET_CONVERT_OX_LIST: "/v2/account/public/fixed/conversion/asset",
  SUBMIT_CONVERSION_OX: "/v2/account/protected/fixed/conversion",
  SWITCH_VALUTS: "/v2/vault/protected/vault/unsubscribe_and_subscribe",
  GET_VALUTS_FEES: "/v2/vault/public/get/transfer/vault/info",
  PUBLIC_HOMEPAGE: "/v2/ohlc/public/homepage",
  GET_EGIRL_LIST: "/v2/lending/public/egirl/competitions",
  GET_EGIRL_PNL: "/v2/accvalue/public/account/pnl?accountId=",
  GET_EGIRL_POSITION: "/v2/accvalue/public/account/pnl/top?accountId="
};
